import { create } from 'zustand'



// Menu Drawer store
interface MenuStore{
    openMenu: boolean;
    setOpenMenu: Function;
}

export const useMenuStore= create<MenuStore>((set) => ({
  openMenu: false,
  setOpenMenu: (e: boolean) => set({ openMenu: e })
}))



