import React from 'react';
import './Advantage.scss';
import { Factory, TrendingUp, Truck, X } from 'lucide-react';

const Advantage = () => {
    const advantages = [
        {title: 'Доставка по всей России', description: 'Доставим продукцию в любую точку страны. Нашу продукцию можно так же приобрести при помощи сервисов Ozon или Wildberries.', icon: <Truck color='#191919'/>},
        {title: 'Производство в России', description: 'Производим продукцию в России. Не боимся санкций.', icon: <Factory color='#191919'/>},
        {title: 'Качество продукции', description: 'Продукция выпускается только из лучших материалов. При изготовлении продукта подбираем нужные пропорции.', icon: <TrendingUp color='#191919'/>}
    ]
    return (
        <div className='advantage'>
            <div className="advantage__title">
                <span>Наши <span className='primary'>преимущества</span></span>
            </div>
            <div className="advantage__description">
                <p>
                    Мы активно улучшаемся на рынке и стараемся стать лучшими из лучших. Благодаря упорной работе мы становимся сильнее система - наш друг.
                </p>
            </div>
            <div className="advantage__items">
                {advantages.map(item => (
                    <div className="advantage__item" key={item.title}>
                    <div className="advantage__item__icon">{item.icon}</div>
                    <div className="advantage__item__title"><p>{item.title}</p></div>
                    <div className="advantage__item__description"><p>{item.description}</p></div>
                    </div>
                ))}
               
            </div>
        </div>
    );
}

export default Advantage;
