import React from "react";
import "./ProductItem.scss";
import { IProductItem } from "../../../types/types";
import OzonIcon from '../../../assets/images/ozon.png'

interface Props {
  data: IProductItem; 
}

const ProductItem = ({ data }: Props) => {
  return (
    <div className="products__item">
      <img src={data.img} alt={data.title} />
      <div className="title">
        <h2>{data.title}</h2>
      </div>
      <div className="body">
        <p>{data.body}</p>
      </div>
      <div className="actions">
        <button>
          Купить
          <img src={OzonIcon} alt="" />
        </button>
      </div>
    </div>
  );
};

export default ProductItem;