import React from 'react';
import './Menu.scss';
import { useMenuStore } from '../../store/store';
import { X } from 'lucide-react';

const Menu = () => {
    const {openMenu, setOpenMenu} = useMenuStore();
    return (
        <>
        {openMenu &&
            <div className="overlay" onClick={() => setOpenMenu(false)}>
                <div className="menu" onClick={(e) => e.stopPropagation()}>
                    <div className="menu__top">
                        <h3>Меню сайта</h3>
                        <button onClick={() => setOpenMenu(false)}><X color='#CAFF33'/></button>
                    </div>
                    <div className="menu__mid">
                        <ul>
                            <li><a href="#">О нас</a></li>
                            <li><a href="#">Преимущества</a></li>
                            <li><a href="#">Товары</a></li>
                            <li><a href="#">Магазины</a></li>
                         
                        </ul>
                    </div>
                    <div className="menu__bottom"></div>
            

                </div>
            </div>
        }
            
          
        </>
    );
}

export default Menu;
