import React from 'react';
import './Products.scss';
import { IProductItem } from '../../types/types';
import ProductItem from './items/ProductItem';

const Products = () => {
    const products: IProductItem[] = [];
    return (
        <div className='products'>
            <div className="products__title">
                <span>Наша <span className='primary'>продукция</span></span>
            </div>
            <div className="products__description">
                <p>
                    Список нашей продукции
                </p>
            </div>
            <div className="products__items">
                {products.length === 0 ? (
                    <div className="no-products">
                        <h2 style={{color: 'white', textAlign:"center", marginTop: '50%'}}>
                            Содержимое не найдено, либо скоро будет доступно!
                        </h2>
                    </div>
                ) : (
                    products.map(product => 
                        <ProductItem key={product.id} data={product}/>
                    )
                )}
            </div>
        </div>
    );
}

export default Products;
