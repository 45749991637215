import React from "react";
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import Hero from "./components/Hero/Hero";
import Advantage from "./components/Advantage/Advantage";
import Footer from "./components/Footer/Footer";
import Products from "./components/Products/Products";
import { Toaster } from "react-hot-toast";

function App() {
  return (

      <div className="App">
        <Menu />

        <div className="container">
          <Header />
          <Hero />
          <Advantage />
          <Products />
        </div>

        <Footer />
        <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          className: '',
          duration: 1500,
          style: {
            background: '#363636',
            color: '#fff',
          },
        }
        }
        />
      </div>
  );
}

export default App;
